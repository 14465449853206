<template>
  <div :class="customClass" :id="elementId">
    <div class="center-container" flex-row-flex-start-center>
      <el-card class="reg-card" shadow="hover" @click.native="registrationSearchClicked">
        <div></div>
        <p>报名查询</p>
        <p>抽签结果、号码布查询、退费等</p>
      </el-card>
      <el-card class="sore-card" shadow="hover" @click.native="scoreSearchClicked">
        <div></div>
        <p>成绩查询</p>
        <p>成绩查询、证书下载</p>
      </el-card>
      <el-card class="image-card" shadow="hover" @click.native="imageSearchClicked">
        <div></div>
        <p>照片查询</p>
        <p>个人照片查询、下载</p>
      </el-card>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">

  import DataConfigMixin from '@/mixins/data-config-mixin'
  import {cloneDeep, isEmpty} from 'lodash'
  export default {
    inject: {
      home: { from: 'home' },
    },
    // extends   : {},
    mixins    : [DataConfigMixin],
    components: {},
    name      : 'SearchComponent',
    props: {
      className: { type: String, default: '' },
    },
    data() {
      return {
        isRaceH5Url: null,
      }
    },
    watch   : {
      // someObject: { handler: function (val, oldVal) { /* ... */ }, deep: true, immediate: true },
    },
    computed: {
      elementId() {return `SearchComponent_${this._uid}`},
      customClass() {return ['SearchComponent', this.className]},
    },
    methods : {
      imageSearchClicked() {
        if (isEmpty(this?.dataConfig?.registrationSearch)) {
          return this.$alert('暂无照片可查询,请耐心等待', '提示', {
            confirmButtonText: '确定',
            callback         : action => {},
          })
        }
        const routerName                                  = 'ImageSearch'
        let { openStatus, dialogText, jumpType, jumpUrl } = this.dataConfig.pictureSearch

        try {
          if (openStatus === true) {
            if (jumpType === 2) {
              return this.$router.push({ name: routerName })
            }
            if (jumpType === 1) {window.open(jumpUrl)}
          }

          if (openStatus === false) {
            return this.$alert(dialogText, '提示', {
              confirmButtonText: '确定',
              callback         : action => {},
            })
          }
        } catch (err) {
          console.error(err)
        }
      },
      scoreSearchClicked() {
        if (isEmpty(this?.dataConfig?.scoreSearch)) {
          return this.$alert('暂未开放，成绩查询', '提示', {
            confirmButtonText: '确定',
            callback         : action => {},
          })
        }

        const routerName                                  = 'ScoreSearch'
        let { openStatus, dialogText, jumpType, jumpUrl } = this.dataConfig.scoreSearch

        try {
          if (openStatus === true) {
            if (jumpType === 2) {
              return this.$router.push({ name: routerName })
            }
            if (jumpType === 1) {window.open(jumpUrl)}
          }

          if (openStatus === false) {
            return this.$alert(dialogText, '提示', {
              confirmButtonText: '确定',
              callback         : action => {},
            })
          }
        } catch (err) {
          console.error(err)
        }

      },
      registrationSearchClicked() {
        if (isEmpty(this?.dataConfig?.registrationSearch)) {
          return this.$alert('暂未开放，报名查询', '提示', {
            confirmButtonText: '确定',
            callback         : action => {},
          })
        }

        const routerName                                  = 'RegistrationSearch'
        let { openStatus, dialogText, jumpType, jumpUrl } = this.dataConfig.registrationSearch

        try {
          if (openStatus === true) {
            if (jumpType === 2) {
              return this.$router.push({ name: routerName })
            }
            if (jumpType === 1) {window.open(jumpUrl)}
          }

          if (openStatus === false) {
            return this.$alert(dialogText, '提示', {
              confirmButtonText: '确定',
              callback         : action => {},
            })
          }
        } catch (err) {
          console.error(err)
        }
      },
    },
    created() {
      // document.documentElement.scrollTop = 0
    },
    destroyed() {},
    mounted() {},
  }
</script>

<style scoped lang="scss" rel="stylesheet/scss">
  // search-component
  // @import "SearchComponent.scss"
  .SearchComponent {
    height     : 552px;
    background : #ffffff;

    .center-container {
      width           : 1200px;
      margin          : 0 auto;
      //padding         : 145px 0 100px 0;
      height          : 552px;
      display         : flex;
      justify-content : space-between;

      ::v-deep .el-card {
        width         : 372px !important;
        height        : 307px;
        background    : #FFFFFF;
        box-shadow    : 0px 5px 15px 1px rgba(0, 0, 0, 0.1000);
        border-radius : 4px;
        cursor        : pointer;
        text-align    : center;
        transition    : 0.2s !important;

        p:nth-of-type(1) {
          font-size   : 28px;
          font-weight : 500;
          color       : #14151E;
        }

        p:nth-of-type(2) {
          font-size   : 20px;
          font-weight : 400;
          color       : #14151E;
        }
      }

      ::v-deep .el-card__body {
        padding : 0;
      }

      //报名查询
      .reg-card div {
        margin          : 49px auto 51px;
        width           : 80px;
        height          : 83px;
        background      : url("../../../../assets/img/home/registration-hover.png") 0 0 no-repeat;
        background-size : 100%;
      }

      .reg-card:hover div {
        background      : url("../../../../assets/img/home/registration.png") 0 0 no-repeat;
        background-size : 100%;
      }

      //成绩查询
      .sore-card div {
        margin          : 49px auto 51px;
        width           : 87px;
        height          : 84px;
        background      : url("../../../../assets/img/home/achievement-hover.png") 0 0 no-repeat;
        background-size : 100%;
      }

      .sore-card:hover div {
        background      : url("../../../../assets/img/home/achievement.png") 0 0 no-repeat;
        background-size : 100%;
      }

      //照片查询
      .image-card div {
        margin          : 49px auto 51px;
        width           : 80px;
        height          : 80px;
        background      : url("../../../../assets/img/home/photo-hover.png") 0 0 no-repeat;
        background-size : 100%;
      }

      .image-card:hover div {
        background      : url("../../../../assets/img/home/photo.png") 0 0 no-repeat;
        background-size : 100%;
      }

      ::v-deep .el-card:hover {
        width      : 409px;
        height     : 338px;
        background : #DC3333;
        box-shadow : 0px 6px 17px 1px rgba(0, 0, 0, 0.1000);
        transition : 0.2s !important;

        p {
          color : #ffffff;
        }
      }
    }
  }
</style>
